import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { USER_TOKEN_KEY } from './constants';
import './InviteSignUp.css';

const API_URL = process.env.REACT_APP_API_URL;

const InviteSignUp = ({ onAuthenticated }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [inviterName, setInviterName] = useState('');
  const navigate = useNavigate();
  const { invite_id } = useParams();

  useEffect(() => {
    const fetchInviterInfo = async () => {
      try {
        const response = await fetch(`${API_URL}/api/v1/platform/invitations/${invite_id}`);
        const data = await response.json();

        if (response.ok) {
          setInviterName(data.inviter_display_name || 'Someone');
          setEmail(data.invitee_email || '');
        } else {
          setError(data.error || 'Failed to fetch invitation details');
        }
      } catch (err) {
        setError(err.message || 'An unexpected error occurred. Please try again.');
      }
    };

    if (invite_id) {
      fetchInviterInfo();
    } else {
      setError('No invitation ID found. Please check your invitation link.');
    }
  }, [invite_id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    if (!email || !password) {
      setError('Email and password are required');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${API_URL}/api/v1/auth/invitesignup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, inviteId: invite_id }),
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.error || 'An error occurred during registration');
        setLoading(false);
        return;
      }

      const { access_token, account_status, flow_step } = data;

      localStorage.setItem(USER_TOKEN_KEY, access_token);

      onAuthenticated();

      switch(flow_step) {
        case 1:
          navigate('/kyc');
          break;
        case 2:
          navigate('/bank-integration');
          break;
        case 3:
          const partnershipResponse = await fetch(`${API_URL}/api/v1/platform/pop_pending_partnership_to_accept`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${access_token}`,
            },
          });

          const partnershipData = await partnershipResponse.json();

          if (partnershipResponse.ok && partnershipData.partnership_id) {
            navigate(`/partner/${partnershipData.partnership_id}`);
          } else {
            navigate('/');
          }
          break;
        default:
          navigate('/');
          break;
      }
    } catch (err) {
      setError(err.message || 'An unexpected error occurred. Please try again.');
      setLoading(false);
    }
  };

  return (
    <div className="invite-signup-container">
      <Form className="invite-signup-form" onSubmit={handleSubmit}>
        <h2 className="text-center">Howdy! Welcome to BillPartner 👋</h2>
        {error ? (
          <p className="text-center text-danger">{error}</p>
        ) : (
          <p className="text-center">
            <span className="inviter-name">{inviterName}</span> has invited you to join BillPartner to make partnership and collaboration payments a breeze. Let's get started by setting up your account...
          </p>
        )}
        <Form.Group controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control 
            type="email" 
            placeholder="email@address.com" 
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="email-input"
            readOnly
            required
          />
        </Form.Group>

        <Form.Group controlId="formPassword" className="mt-3">
          <Form.Label>Password</Form.Label>
          <Form.Control 
            type="password" 
            placeholder="password" 
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Form.Group>

        <div className="text-right mt-2">
          <Link to="/forgot-password" className="text-right no-underline">Forgot password</Link>
        </div>

        <Button 
          variant="primary" 
          type="submit" 
          className="w-100 mt-3 btn-blue"
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Continue'}
        </Button>

        <div className="text-center mt-3">
          <Link to="/signin" className="no-underline">I already have an account</Link>
        </div>

        <p className="text-center mt-3" style={{ fontSize: '0.8rem' }}>
          By signing up and using the service, you agree to our{' '}
          <a 
            href="https://billpartner.co/termsofservice" 
            target="_blank" 
            rel="noopener noreferrer" 
            style={{ color: '#007bff', textDecoration: 'none' }}
          >
            Terms of Service
          </a>
          {' '}and{' '}
          <a 
            href="https://billpartner.co/privacypolicy" 
            target="_blank" 
            rel="noopener noreferrer" 
            style={{ color: '#007bff', textDecoration: 'none' }}
          >
            Privacy Policy
          </a>.
        </p>
      </Form>
    </div>
  );
};

export default InviteSignUp;