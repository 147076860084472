import React, { useState, useCallback, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { USER_TOKEN_KEY, STRIPE_ENDPOINT } from './constants';
import './BankIntegration.css';

const API_URL = process.env.REACT_APP_API_URL;
const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

const BankIntegration = () => {
  const [clientSecret, setClientSecret] = useState(null);
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();

  const createFinancialConnectionsSession = useCallback(async () => {
    try {
      const token = localStorage.getItem(USER_TOKEN_KEY);
      const response = await axios.post(`${API_URL}/${STRIPE_ENDPOINT}/create_financial_connections_session`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setClientSecret(response.data.client_secret);
    } catch (err) {
      console.error('Error creating Financial Connections session:', err);
      setError('Failed to initialize bank connection. Please try again.');
    }
  }, []);

  useEffect(() => {
    createFinancialConnectionsSession();
  }, [createFinancialConnectionsSession]);

  const handleBankConnection = useCallback(async () => {
    if (!clientSecret) return;
    setIsProcessing(true);
    setError(null);

    try {
      const stripe = await loadStripe(STRIPE_PUBLIC_KEY);
      const { error, token, financialConnectionsSession } = await stripe.collectBankAccountToken({
        clientSecret,
      });

      if (error) {
        throw new Error('Failed to connect bank account. Please try again.');
      }

      // Handle successful connection
      const authToken = localStorage.getItem(USER_TOKEN_KEY);
      const response = await axios.post(
        `${API_URL}/${STRIPE_ENDPOINT}/handle_financial_connections_success`,
        { 
          session_id: financialConnectionsSession.id,
          bank_account_token: token
        },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      const { flow_step, checkingAccountFound } = response.data;

      if (!checkingAccountFound) {
        throw new Error('No checking account was found. Please connect a checking account.');
      }

      // Navigate based on flow_step
      if (flow_step > 2) {
        const partnershipResponse = await axios.get(
          `${API_URL}/api/v1/platform/pop_pending_partnership_to_accept`,
          { headers: { Authorization: `Bearer ${authToken}` } }
        );

        if (partnershipResponse.data.partnership_id) {
          navigate(`/partner/${partnershipResponse.data.partnership_id}`);
        } else {
          navigate('/');
        }
      } else {
        navigate('/');
      }

    } catch (err) {
      console.error('Error handling bank connection:', err);
      setError(err.message || 'Failed to process bank connection. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  }, [clientSecret, navigate]);

  if (!clientSecret) return <div className="loading">Loading...</div>;

  return (
    <div className="bank-integration-container">
      <div className="bank-integration-card">
        <h3>Link Your Bank Account</h3>
        <p className="description">Please connect your checking account for ACH debits</p>
        {isProcessing ? (
          <div className="processing">
            <div className="spinner"></div>
            <p>Processing bank information...</p>
          </div>
        ) : error ? (
          <div className="error">
            <p>{error}</p>
            <button onClick={() => { setError(null); createFinancialConnectionsSession(); }} className="retry-button">
              Try Again
            </button>
          </div>
        ) : (
          <button onClick={handleBankConnection} className="connect-button">
            <span>Connect Your Bank</span>
            <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path d="M5 12h14M12 5l7 7-7 7"/>
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};

export default BankIntegration;