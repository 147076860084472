import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { USER_TOKEN_KEY } from './constants';
import './Auth.css';

const API_URL = process.env.REACT_APP_API_URL;

const SignIn = ({ onAuthenticated }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await fetch(`${API_URL}/api/v1/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.error || 'An error occurred during sign in');
        return;
      }

      const { access_token, flow_step } = data;

      // Store the token in localStorage
      localStorage.setItem(USER_TOKEN_KEY, access_token);

      // Call the onAuthenticated function to update the app's authentication state
      onAuthenticated();

      // Redirect based on flow_step
      switch(flow_step) {
        case 1:
          navigate('/kyc');
          break;
        case 2:
          navigate('/bank-integration');
          break;
        case 3:
          // Check for pending partnership for existing accounts
          const partnershipResponse = await fetch(`${API_URL}/api/v1/platform/pop_pending_partnership_to_accept`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${access_token}`, // Pass the token in the Authorization header
            },
          });

          const partnershipData = await partnershipResponse.json();

          if (partnershipResponse.ok && partnershipData.partnership_id) {
            navigate(`/partner/${partnershipData.partnership_id}`);
          } else {
            navigate('/');
          }
          break;
        default:
          navigate('/');
          break;
      }
    } catch (err) {
      setError(err.message || 'An unexpected error occurred. Please try again.');
    }
  };

  return (
    <Container className="d-flex align-items-center justify-content-center min-vh-100">
      <Row className="w-100">
        <Col md={{ span: 6, offset: 3 }}>
          <h2 className="text-center">Sign In</h2>
          <p className="text-center">Welcome back to BillPartner!</p>
          <Form className="auth-form" onSubmit={handleSubmit}>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control 
                type="email" 
                placeholder="email@address.com" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formPassword" className="mt-3">
              <Form.Label>Password</Form.Label>
              <Form.Control 
                type="password" 
                placeholder="password" 
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              {error && <div className="text-danger mt-2">{error}</div>}
            </Form.Group>

            <div className="text-right mt-2">
              <Link to="/forgot-password" className="text-right no-underline">Forgot password</Link>
            </div>

            <Button variant="primary" type="submit" className="w-100 mt-3 btn-blue">
              Continue
            </Button>

            <div className="text-center mt-3">
              <Link to="/signup" className="no-underline">Create new account</Link>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default SignIn;

