import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Button, Form, Card, Alert } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { USER_TOKEN_KEY, PLATFORM_ENDPOINT, STRIPE_ENDPOINT } from './constants';
import axios from 'axios';
import { CheckCircle, AlertTriangle, Clock, AlertCircle } from 'lucide-react';

const API_URL = process.env.REACT_APP_API_URL;

const StripeKYCStatus = ({ status, onboardingLink }) => {
  const getStatusInfo = () => {
    switch (status) {
      case 'unverified':
        return {
          color: 'warning',
          message: 'Incomplete. This step is crucial for billing partners and collecting funds to your bank account. <strong> Recommended Note: Select that you are individual, not a company, and use our url for the website field to have a smooth KYC experience.</strong>',
          showLink: true
        };
      case 'pending_verification':
      case 'verification_pending':
        return {
          color: 'info',
          message: 'Pending verification. We\'ll update you once the process is complete.',
          showLink: false
        };
      case 'document_required':
        return {
          color: 'warning',
          message: 'Additional documents required. This is necessary for billing partners.',
          showLink: true
        };
      case 'verified':
        return {
          color: 'success',
          message: 'Verified. You can now bill partners successfully.',
          showLink: false
        };
      default:
        return {
          color: 'warning',
          message: 'Status unknown. Please complete the process to ensure you can bill partners.',
          showLink: true
        };
    }
  };

  const { color, message, showLink } = getStatusInfo();

  return (
    <Card className="mb-4">
      <Card.Body>
        <div className="d-flex align-items-center mb-2">
          <AlertCircle className="me-2" color={color === 'success' ? 'green' : 'orange'} />
          <Card.Title className="mb-0">Stripe KYC Status</Card.Title>
        </div>
        <p className="text-muted mb-3" dangerouslySetInnerHTML={{ __html: message }}></p>
        {showLink && onboardingLink && (
          <Button 
            variant="primary" 
            href={onboardingLink} 
            target="_blank" 
            rel="noopener noreferrer"
          >
            Complete Stripe KYC
          </Button>
        )}
      </Card.Body>
    </Card>
  );
};

const Settings = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem(USER_TOKEN_KEY);
        if (!token) {
          navigate('/signin');
          return;
        }

        const response = await axios.get(`${API_URL}/${PLATFORM_ENDPOINT}/personal-info`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserInfo(response.data);
      } catch (error) {
        console.error('Error fetching user information:', error);
      }
    };

    fetchUserInfo();
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem(USER_TOKEN_KEY);
    navigate('/signin');
  };

  const handleToggleFreezeAccount = async () => {
    try {
      const token = localStorage.getItem(USER_TOKEN_KEY);
      const newFreezeStatus = !userInfo.freeze_account;

      await axios.post(
        `${API_URL}/${STRIPE_ENDPOINT}/freeze_account`,
        { freeze: newFreezeStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setUserInfo((prevState) => ({
        ...prevState,
        freeze_account: newFreezeStatus,
      }));
    } catch (error) {
      console.error('Error toggling freeze account:', error);
    }
  };

  const handleChangeAccount = () => {
    navigate('/bank-integration');
  };

  if (!userInfo) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>Settings</h2>
      <Tabs defaultActiveKey="profile" id="settings-tabs">
        <Tab eventKey="profile" title="Profile">
          <div className="mt-3">
            <h4>Contact Information</h4>
            <p><strong>Display Name:</strong> {userInfo.display_name}</p>
            <p><strong>Email:</strong> {userInfo.email}</p>
            <p><strong>Account Created:</strong> {new Date(userInfo.created_at).toLocaleDateString()}</p>
            <Button variant="danger" className="mt-3" onClick={handleLogout}>
              Logout
            </Button>
          </div>
        </Tab>
        <Tab eventKey="banks" title="Banks">
          <div className="mt-3">
            <h4>Bank Account Information</h4>
            {userInfo.bank_name ? (
              <>
                <p><strong>Bank Name:</strong> {userInfo.bank_name}</p>
                <p><strong>Account Number(last 4):</strong> ****{userInfo.account_number_last_4}</p>
                {/*<p><strong>Status:</strong> {userInfo.bank_account_status}</p>*/}
                <Button variant="link" onClick={handleChangeAccount}>Change account</Button>
              </>
            ) : (
              <p>No bank account linked</p>
            )}
            <h4 className="mt-4">Freeze Bank Account (Restrict Money Debits):</h4>
            <div className="d-flex align-items-center">
              <Form.Check
                type="switch"
                id="freeze-account-switch"
                checked={userInfo.freeze_account}
                onChange={handleToggleFreezeAccount}
                label={userInfo.freeze_account ? "On" : "Off"}
                className={`ml-2 ${userInfo.freeze_account ? 'text-danger' : 'text-success'}`}
              />
            </div>
          </div>
        </Tab>
        <Tab eventKey="kyc" title="KYC Information">
          <div className="mt-3">
            <StripeKYCStatus 
              status={userInfo.stripe_connect_account_status || 'unverified'} 
              onboardingLink={userInfo.stripe_connect_onboarding_link}
            />
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h4>KYC Information</h4>
              <Link 
                to="/kyc" 
                className="text-primary text-decoration-none"
              >
                Edit
              </Link>
            </div>
            <p><strong>First Name:</strong> {userInfo.first_name}</p>
            <p><strong>Last Name:</strong> {userInfo.last_name}</p>
            <p><strong>Phone Number:</strong> {userInfo.phone_number}</p>
            <p><strong>Address Line 1:</strong> {userInfo.address_line1}</p>
            <p><strong>Address Line 2:</strong> {userInfo.address_line2 || 'N/A'}</p>
            <p><strong>City:</strong> {userInfo.city}</p>
            <p><strong>State:</strong> {userInfo.state}</p>
            <p><strong>Zip Code:</strong> {userInfo.zip_code}</p>
            <p><strong>SSN Last 4:</strong> {userInfo.ssn_last4}</p>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default Settings;