import React, { useState } from 'react';
import { Modal, Form, Button, Alert, FormControl } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { USER_TOKEN_KEY, PLATFORM_ENDPOINT } from './constants';

const API_URL = process.env.REACT_APP_API_URL;
const REACT_APP_URL = process.env.REACT_APP_URL;

const AddBillPartnerModal = ({ show, onHide, onSuccess }) => {
  const [partnershipName, setPartnershipName] = useState('');
  const [partnerEmail, setPartnerEmail] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [inviteLink, setInviteLink] = useState('');
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    const token = localStorage.getItem(USER_TOKEN_KEY);

    try {
      // Step 1: Create the Bill Partnership
      const createResponse = await axios.post(`${API_URL}/${PLATFORM_ENDPOINT}/create-bill-partner-request`, 
        {
          partnership_name: partnershipName,
          partner_invite_email: partnerEmail,
          description: description
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );

      const { id: partnershipId } = createResponse.data;

      // Step 2: Fetch the partnership details to get the invitation ID
      const detailsResponse = await axios.get(`${API_URL}/${PLATFORM_ENDPOINT}/billpartnerships/${partnershipId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const { partnership_invitation_id } = detailsResponse.data;
      setInviteLink(`${REACT_APP_URL}/invites/${partnership_invitation_id}`);

      // Clear form fields
      setPartnershipName('');
      setPartnerEmail('');
      setDescription('');

      // Trigger data refresh
      onSuccess();
      setSubmitted(true);

    } catch (err) {
      console.error('Error in handleSubmit:', err);
      let errorMessage = 'An unexpected error occurred. Please try again.';
      
      if (err.response) {
        console.error('Error response:', err.response);
        errorMessage = err.response.data?.error || err.response.data?.message || err.response.statusText || errorMessage;
      } else if (err.request) {
        console.error('Error request:', err.request);
        errorMessage = 'No response received from the server. Please check your connection and try again.';
      } else {
        console.error('Error message:', err.message);
        errorMessage = err.message || errorMessage;
      }
      
      setError(errorMessage);
      
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    if (submitted) {
      navigate('/');
    }
    onHide();
    setSubmitted(false);
    setInviteLink('');
    setCopied(false);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(inviteLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{submitted ? 'Invitation Sent 🥳' : 'Add a Bill Partner to Charge 💸'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {error && (
          <div style={{ color: 'red', marginBottom: '15px' }}>
            <strong>Error:</strong> {error}
          </div>
        )}
        {submitted ? (
          <div style={{ textAlign: 'center', padding: '20px 0' }}>
            <p style={{ fontSize: '1.1em', marginBottom: '15px' }}>
              An email has been sent to your partner inviting them to the bill partnership. Once they accept, we will notify you.
            </p>
            {inviteLink && (
              <>
                <h5>Copy Invite Link:</h5>
                <div className="position-relative">
                  <FormControl 
                    readOnly 
                    value={inviteLink}
                    onClick={handleCopyLink}
                    style={{ cursor: 'pointer', textAlign: 'center', backgroundColor: '#f8f9fa', paddingRight: '60px' }}
                  />
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={handleCopyLink}
                    style={{
                      position: 'absolute',
                      right: '5px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                  >
                    {copied ? 'Copied!' : 'Copy'}
                  </Button>
                </div>
                {copied && (
                  <Alert variant="success" className="mt-2">
                    Invite link copied to clipboard!
                  </Alert>
                )}
              </>
            )}
          </div>
        ) : (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="partnershipName">
              <Form.Label><strong>Name Your Partnership</strong></Form.Label>
              <Form.Control
                type="text"
                value={partnershipName}
                onChange={(e) => setPartnershipName(e.target.value)}
                placeholder="Lunar Agency <> Sky Bri"
                required
              />
            </Form.Group>
            <Form.Group controlId="description" className="mt-2">
              <Form.Label><strong>Description</strong></Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="A partnership to send bills for revenue share"
                required
              />
            </Form.Group>
            <Form.Group controlId="partnerEmail" className="mt-2">
              <Form.Label><strong>Partner's Email</strong></Form.Label>
              <Form.Control
                type="email"
                value={partnerEmail}
                onChange={(e) => setPartnerEmail(e.target.value)}
                placeholder="skylar.bri@gmail.com"
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3" disabled={loading}>
              {loading ? 'Submitting...' : 'Submit'}
            </Button>
          </Form>
        )}
      </Modal.Body>
      {submitted && (
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>Close</Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default AddBillPartnerModal;





