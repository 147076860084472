// App.js
import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Col, Button, Offcanvas } from 'react-bootstrap';
import axios from 'axios';
import Sidebar from './Sidebar';
import Dashboard from './Dashboard';
import HomeDashboard from './HomeDashboard';
import Settings from './Settings';
import SignIn from './SignIn';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import AddBillPartnerModal from './AddBillPartnerModal';
import './App.css';
import './Auth.css';
import './KYCForm.css';
import KYCForm from './KYCForm';
import InviteSignUp from './InviteSignUp';
import BankIntegration from './BankIntegration';
import EmailVerificationPage from './EmailVerificationPage';
import Intercom from '@intercom/messenger-js-sdk';
import StripeConnectRefresh from './StripeConnectRefresh';
import CancelPage from './CancelPage';

import { PLATFORM_ENDPOINT, USER_TOKEN_KEY } from './constants';

const API_URL = process.env.REACT_APP_API_URL;

const App = () => {

  const [showModal, setShowModal] = useState(false);
  const [partners, setPartners] = useState({
    active: [],
    pending: [],
    canceled: []
  });
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchPartners = useCallback(async () => {
    try {
      const token = localStorage.getItem(USER_TOKEN_KEY);
      if (!token) {
        console.log('No token found, skipping fetch');
        return;
      }
      const response = await axios.get(`${API_URL}/${PLATFORM_ENDPOINT}/get-bill-partnerships`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPartners(response.data);
    } catch (error) {
      console.error('Error fetching bill partnerships:', error);
    }
  }, []);

  useEffect(() => {
    // Initialize Intercom
    Intercom({
      app_id: 'ogov0oji',
    });
  }, []);

  useEffect(() => {
    const token = localStorage.getItem(USER_TOKEN_KEY);
    if (token) {
      setIsAuthenticated(true);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchPartners();
    }
  }, [isAuthenticated, fetchPartners]);

  const handleAuthentication = () => {
    setIsAuthenticated(true);
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/signin" element={<SignIn onAuthenticated={handleAuthentication} />} />
        <Route path="/signup" element={<SignUp onAuthenticated={handleAuthentication} />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/invites/:invite_id" element={<InviteSignUp onAuthenticated={handleAuthentication} />} />
        <Route path="/verify-email/:verification_uuid" element={<EmailVerificationPage />} />
        <Route path="/refresh-stripe-connect-onboarding" element={<StripeConnectRefresh />} />
        <Route path="/cancel/:billPartnershipId/:paymentIntentId" element={<CancelPage />} />
        <Route path="*" element={
          <AuthenticatedRoutes 
            partners={partners} 
            showModal={showModal} 
            handleShowModal={handleShowModal} 
            handleCloseModal={handleCloseModal}
            fetchPartners={fetchPartners}
            onAuthenticated={handleAuthentication}
            isAuthenticated={isAuthenticated}
          />
        } />
      </Routes>
    </Router>
  );
};

const AuthenticatedRoutes = ({ partners, showModal, handleShowModal, handleCloseModal, fetchPartners, onAuthenticated, isAuthenticated }) => {
  const navigate = useNavigate();
  const { invite_id } = useParams(); 

  useEffect(() => {
    const token = localStorage.getItem(USER_TOKEN_KEY);
    if (!token && !invite_id) {
      navigate('/signin');
    } else if (token) {
      fetchPartners();
    }
  }, [navigate, fetchPartners, invite_id]);

  return (
    <Routes>
      <Route path="/kyc" element={<StandaloneRoute isAuthenticated={isAuthenticated}><KYCForm /></StandaloneRoute>} />
      <Route path="/bank-integration" element={<StandaloneRoute isAuthenticated={isAuthenticated}><BankIntegration /></StandaloneRoute>} />
      <Route path="/*" element={
        <MainLayout 
          partners={partners} 
          showModal={showModal} 
          handleShowModal={handleShowModal} 
          handleCloseModal={handleCloseModal} 
          fetchPartners={fetchPartners}
        />
      } />
    </Routes>
  );
};

const StandaloneRoute = ({ isAuthenticated, children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/signin');
    }
  }, [isAuthenticated, navigate]);

  return (
    <Container fluid className="standalone-container">
      {children}
    </Container>
  );
};

const MainLayout = ({ partners, showModal, handleShowModal, handleCloseModal, fetchPartners }) => {
  const [sidebarShow, setSidebarShow] = useState(false);

  const handleSidebarClose = () => setSidebarShow(false);
  const handleSidebarShow = () => setSidebarShow(true);

  return (
    <Container fluid className="app-container">
      <Row className="h-100">
        {/* Mobile Sidebar Button */}
        <Col xs={12} className="d-md-none mb-2">
          <Button variant="primary" onClick={handleSidebarShow}>
            ☰ Menu
          </Button>
        </Col>

        {/* Desktop Sidebar */}
        <Col md={3} className="p-3 sidebar-container d-none d-md-block">
          <Sidebar partners={partners} onAddPartner={handleShowModal} />
        </Col>

        {/* Main Content Area */}
        <Col xs={12} md={9} className="p-3 dashboard-container">
          <Routes>
            <Route path="/settings" element={<Settings />} />
            <Route path="/partner/:id" element={<Dashboard partners={partners} />} />
            <Route path="/" element={<HomeDashboard />} />
          </Routes>
        </Col>
      </Row>

      {/* Mobile Sidebar Offcanvas */}
      <Offcanvas show={sidebarShow} onHide={handleSidebarClose} placement="start">
        <Offcanvas.Header closeButton>
          {/* Removed the Offcanvas.Title */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Sidebar partners={partners} onAddPartner={handleShowModal} isMobile={true} onSidebarClose={handleSidebarClose} />
        </Offcanvas.Body>
      </Offcanvas>

      {/* Add Bill Partner Modal */}
      <AddBillPartnerModal 
        show={showModal} 
        onHide={handleCloseModal}
        onSuccess={fetchPartners}
      />
    </Container>
  );
};

export default App;


