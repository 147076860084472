import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { USER_TOKEN_KEY } from './constants';
import './Auth.css';

const API_URL = process.env.REACT_APP_API_URL;

const SignUp = ({ onAuthenticated }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    // Basic client-side validation
    if (!email || !password) {
      setError('Email and password are required');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${API_URL}/api/v1/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.error || 'An error occurred during registration');
        setLoading(false);
        return;
      }

      const { access_token } = data;

      // Store the token in localStorage
      localStorage.setItem(USER_TOKEN_KEY, access_token);

      // Call the onAuthenticated function to update the app's authentication state
      onAuthenticated();

      // Redirect to the KYC page
      navigate('/kyc');
    } catch (err) {
      setError(err.message || 'An unexpected error occurred. Please try again.');
      setLoading(false);
    }
  };

  return (
    <Container className="d-flex align-items-center justify-content-center min-vh-100">
      <Row className="w-100">
        <Col md={{ span: 6, offset: 3 }}>
          <h2 className="text-center">Howdy! Welcome to BillPartner  👋</h2>
          <p className="text-center">Let's set up your BillPartner account...</p>
          <Form className="auth-form" onSubmit={handleSubmit}>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control 
                type="email" 
                placeholder="email@address.com" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formPassword" className="mt-3">
              <Form.Label>Password</Form.Label>
              <Form.Control 
                type="password" 
                placeholder="password" 
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              {error && <div className="text-danger mt-2">{error}</div>}
            </Form.Group>

            <div className="text-right mt-2">
              <Link to="/forgot-password" className="text-right no-underline">Forgot password</Link>
            </div>

            <Button 
              variant="primary" 
              type="submit" 
              className="w-100 mt-3 btn-blue"
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Continue'}
            </Button>

            <div className="text-center mt-3">
              <Link to="/signin" className="no-underline">I already have an account</Link>
            </div>
          </Form>
          
          <p className="text-center mt-3" style={{ fontSize: '0.8rem' }}>
            By signing up and using the service, you agree to our{' '}
            <a 
              href="https://billpartner.co/termsofservice" 
              target="_blank" 
              rel="noopener noreferrer" 
              style={{ color: '#007bff', textDecoration: 'none' }}
            >
              Terms of Service
            </a>
            {' '}and{' '}
            <a 
              href="https://billpartner.co/privacypolicy" 
              target="_blank" 
              rel="noopener noreferrer" 
              style={{ color: '#007bff', textDecoration: 'none' }}
            >
              Privacy Policy
            </a>.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default SignUp;
