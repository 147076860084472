import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Auth.css';

const API_URL = process.env.REACT_APP_API_URL;

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    setLoading(true);

    if (!email) {
      setError('Email is required');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${API_URL}/api/v1/auth/reset-password-request`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'An error occurred');
      }

      setMessage('Password reset link sent successfully. Please check your email.');
      setIsSubmitted(true);
    } catch (err) {
      setError(err.message || 'An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="d-flex align-items-center justify-content-center min-vh-100">
      <Row className="w-100">
        <Col md={{ span: 6, offset: 3 }}>
          <h2 className="text-center">Forgot Password</h2>
          {isSubmitted ? (
            <div className="text-center">
              <Alert variant="success" className="mt-3">{message}</Alert>
              <p className="mt-3">
                A reset email has just been sent. Didn't receive the email? Check your spam folder, or{' '}
                <Button variant="link" className="p-0" onClick={() => setIsSubmitted(false)}>
                  try again
                </Button>
              </p>
              <Link to="/signin" className="btn btn-primary mt-3">Back to Sign In</Link>
            </div>
          ) : (
            <Form className="auth-form" onSubmit={handleSubmit}>
              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control 
                  type="email" 
                  placeholder="Enter your email" 
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>

              {error && <Alert variant="danger" className="mt-3">{error}</Alert>}

              <Button 
                variant="primary" 
                type="submit" 
                className="w-100 mt-3 btn-blue"
                disabled={loading}
              >
                {loading ? 'Sending...' : 'Send Reset Link'}
              </Button>

              <div className="text-center mt-3">
                <Link to="/signin" className="no-underline">Back to Sign In</Link>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;