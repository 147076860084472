import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { PLATFORM_ENDPOINT, USER_TOKEN_KEY } from './constants';

const API_URL = process.env.REACT_APP_API_URL;

const BillingHistory = ({ bp_id }) => {
  const [billingHistory, setBillingHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBillingHistory = async () => {
      const token = localStorage.getItem(USER_TOKEN_KEY);

      try {
        setLoading(true);
        const response = await fetch(`${API_URL}/${PLATFORM_ENDPOINT}/billing-history/${bp_id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Billing History Data:', data);
          setBillingHistory(data);
          setError(null);
        } else {
          throw new Error('Failed to fetch billing history.');
        }
      } catch (err) {
        console.error('Error fetching billing history:', err);
        setError(err.message);
        setBillingHistory([]);
      } finally {
        setLoading(false);
      }
    };

    fetchBillingHistory();
  }, [bp_id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!billingHistory || billingHistory.length === 0) {
    return <div>No billing history available.</div>;
  }

  return (
    <div>
      <h3 className="mt-4">Billing History</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Timestamp</th>
            <th>Amount</th>
            <th>Description</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {billingHistory.map((intent) => (
            <tr key={intent.id}>
              <td>{new Date(intent.created * 1000).toLocaleString()}</td>
              <td>${(intent.amount / 100).toFixed(2)}</td>
              <td>{intent.description || 'No Description'}</td>
              <td className={
                intent.status === 'succeeded'
                  ? 'text-success'       // Green for succeeded
                  : intent.status === 'canceled'
                    ? 'text-danger'       // Red for cancelled
                    : intent.status === 'requires_confirmation'
                      ? 'text-warning'    // Yellow for pending (requires_confirmation)
                      : intent.status === 'processing'
                        ? 'text-primary'  // Blue for processing
                        : 'text-warning'  // Default yellow for any other status
              }>
                {intent.status === 'requires_confirmation' 
                  ? 'Pending' 
                  : intent.status.charAt(0).toUpperCase() + intent.status.slice(1)}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default BillingHistory;
