import React, { useEffect } from 'react';
import axios from 'axios';
import { USER_TOKEN_KEY, PLATFORM_ENDPOINT } from './constants';

const API_URL = process.env.REACT_APP_API_URL;

const StripeConnectRefresh = () => {
  useEffect(() => {
    const refreshOnboarding = async () => {
      try {
        const token = localStorage.getItem(USER_TOKEN_KEY);
        const response = await axios.get(`${API_URL}/${PLATFORM_ENDPOINT}/refresh-stripe-connect-onboarding`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        
        // Redirect to the new onboarding link
        window.location.href = response.data.onboarding_link;
      } catch (error) {
        console.error('Error refreshing Stripe Connect onboarding:', error);
        // Redirect to dashboard or show an error message
        window.location.href = '/dashboard';
      }
    };

    refreshOnboarding();
  }, []);

  return <div>Refreshing Stripe Connect onboarding...</div>;
};

export default StripeConnectRefresh;