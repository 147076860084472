// Sidebar.js
import React, { useState } from 'react';
import { ListGroup, Nav, Button, Badge } from 'react-bootstrap';
import { FaCog } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './Sidebar.css';

const Sidebar = ({ partners, onAddPartner, isMobile, onSidebarClose }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('active');

  const handleSelect = (selectedKey) => {
    setActiveTab(selectedKey);
  };

  const handlePartnerClick = (partnerId) => {
    navigate(`/partner/${partnerId}`);
    if (isMobile && onSidebarClose) {
      onSidebarClose();
    }
  };

  const noPartnersStyle = {
    color: '#4A4A4A',
    fontWeight: 'bold',
    padding: '10px',
    textAlign: 'center',
  };

  const renderPartnerList = () => {
    if (partners[activeTab].length === 0) {
      return (
        <div style={noPartnersStyle}>
          There are currently no {activeTab} <br />bill partnerships
        </div>
      );
    }

    return (
      <ListGroup className="mt-3 flex-grow-1">
        {partners[activeTab].map((partner) => (
          <ListGroup.Item 
            key={partner.partnership_id} 
            action 
            onClick={() => handlePartnerClick(partner.partnership_id)}
          >
            {partner.partnership_name} <br /> {partner.partner_email}
          </ListGroup.Item>
        ))}
      </ListGroup>
    );
  };

  const pendingCount = partners.pending.length;

  return (
    <div className="d-flex flex-column h-100 sidebar-container">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h3 onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>BillPartner 💳</h3>
        <FaCog style={{ cursor: 'pointer', fontSize: '1.5rem' }} onClick={() => navigate('/settings')} />
      </div>
      <Nav variant="tabs" activeKey={activeTab} onSelect={handleSelect}>
        <Nav.Item>
          <Nav.Link eventKey="active">Active</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="pending" className="nav-link-pending">
            Pending {pendingCount > 0 && (
              <Badge bg="warning" pill className="pending-badge">
                {pendingCount}
              </Badge>
            )}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="canceled">Canceled</Nav.Link>
        </Nav.Item>
      </Nav>
      {renderPartnerList()}
      <Button variant="primary" className="mt-auto mx-auto mb-3 btn-lg" onClick={onAddPartner}>
        + Add Bill Partnership
      </Button>
    </div>
  );
};

export default Sidebar;






