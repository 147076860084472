import React, { useState, useEffect } from 'react';
import { Card, Container, Spinner } from 'react-bootstrap';
import { ArrowLeft } from 'lucide-react';
import axios from 'axios';
import { USER_TOKEN_KEY, PLATFORM_ENDPOINT } from './constants';

const API_URL = process.env.REACT_APP_API_URL;

const HomeDashboard = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [partnerCount, setPartnerCount] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem(USER_TOKEN_KEY);
        if (!token) {
          setLoading(false);
          return;
        }

        const [userResponse, partnerResponse] = await Promise.all([
          axios.get(`${API_URL}/${PLATFORM_ENDPOINT}/personal-info`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${API_URL}/${PLATFORM_ENDPOINT}/get-bill-partnerships`, {
            headers: { Authorization: `Bearer ${token}` },
          })
        ]);

        setUserInfo(userResponse.data);
        setPartnerCount(partnerResponse.data.active.length);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <Container className="home-dashboard py-4">
      <h2 className="mb-2">Hey {userInfo?.display_name || 'there'}!</h2>
      <h4 className="text-muted mb-4">Here's your dashboard overview.</h4>
      
      <Card className="shadow-sm mb-4">
        <Card.Body className="text-center">
          <Card.Title className="mb-3">Your Bill Partnerships</Card.Title>
          <Card.Text className="display-1 fw-bold" style={{ color: '#007bff' }}>{partnerCount}</Card.Text>
        </Card.Body>
      </Card>
      
      <div className="d-flex justify-content-center align-items-center mt-4">
        <ArrowLeft className="me-3" size={32} />
        <p className="fs-4 fw-bold text-dark mb-0">
          Click on your bill partnerships in the sidebar to create charges and view bill history.
        </p>
      </div>
    </Container>
  );
};

export default HomeDashboard;