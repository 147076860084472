import React, { useState, useEffect, useCallback } from 'react';
import { Card, Button, Tabs, Tab, Alert, FormControl } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { PLATFORM_ENDPOINT, USER_TOKEN_KEY } from './constants';
import BillPartnerDetails from './BillPartnerDetails';
import ChargeForm from './ChargeForm';
import BillingHistory from './BillingHistory';

const API_URL = process.env.REACT_APP_API_URL;
const REACT_APP_URL = process.env.REACT_APP_URL;

const Dashboard = () => {
  const { id } = useParams();  // Extract bp_id from URL
  const [partnerDetails, setPartnerDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('charge');
  const [refreshKey, setRefreshKey] = useState(0);
  const [copied, setCopied] = useState(false);

  const fetchPartnerDetails = useCallback(async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem(USER_TOKEN_KEY);
      const response = await axios.get(`${API_URL}/${PLATFORM_ENDPOINT}/billpartnerships/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPartnerDetails(response.data);
      setError(null);
    } catch (error) {
      setError('Error fetching partner details.');
      console.error('Error fetching partner details:', error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchPartnerDetails();
    setActiveTab('charge');
    setRefreshKey(prevKey => prevKey + 1);
  }, [id, fetchPartnerDetails]);

  const handleAccept = async () => {
    try {
      const token = localStorage.getItem(USER_TOKEN_KEY);
      await axios.post(
        `${API_URL}/${PLATFORM_ENDPOINT}/accept-bill-partner`,
        { partnership_id: id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchPartnerDetails();
    } catch (error) {
      setError('Error accepting the partnership.');
      console.error('Error accepting the partnership:', error);
    }
  };

  const handleDecline = async () => {
    try {
      const token = localStorage.getItem(USER_TOKEN_KEY);
      await axios.delete(
        `${API_URL}/${PLATFORM_ENDPOINT}/decline-bill-partner`,
        {
          data: { partnership_id: id },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchPartnerDetails();
    } catch (error) {
      setError('Error declining the partnership.');
      console.error('Error declining the partnership:', error);
    }
  };

  const handleTabSelect = (key) => {
    setActiveTab(key);
    setRefreshKey(prevKey => prevKey + 1);
  };

  const handleCopyLink = () => {
    const link = `${REACT_APP_URL}/invites/${partnerDetails.partnership_invitation_id}`;
    navigator.clipboard.writeText(link);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!partnerDetails) {
    return <div className="dashboard">Please select a bill partner.</div>;
  }

  const { 
    partnership_name, 
    status, 
    owner, 
    partner, 
    user_role, 
    description,
    partnership_type,
    partnership_invitation_id,
  } = partnerDetails;

  const renderContent = () => {
    switch (status) {
      case 'active':
        return (
          <>
            <h2>{partnership_name}</h2>
            <p><strong>Owner:</strong> {owner.display_name}</p>
            <p><strong>Members:</strong> {owner.display_name}, {partner.display_name}</p>
            <Tabs 
              activeKey={activeTab} 
              onSelect={handleTabSelect} 
              id="uncontrolled-tab-example" 
              className="mb-3"
            >
              <Tab eventKey="charge" title="Charge">
                {user_role === 'owner' ? (
                  <ChargeForm partner={partnerDetails} bp_id={id} />
                ) : (
                  <p>Hooray 🥳 You are all set to go! The owner of the bill partnership will bill you here. You have access to seeing the bill history and details of this page. To access charging accounts create a bill partnership with someone you wish to charge expenses. </p>
                )}
              </Tab>
              <Tab eventKey="billing-history" title="Billing History">
                <BillingHistory key={`history-${refreshKey}-${id}`} bp_id={id} />
              </Tab>
              <Tab eventKey="details" title="Details">
                <BillPartnerDetails key={`details-${refreshKey}-${id}`} bp_id={id} />
              </Tab>
            </Tabs>
          </>
        );
      case 'pending':
        if (user_role === 'partner') {
          return (
            <>
              <h2>{partnership_name}</h2>
              <Card className="my-4 p-4" style={{ 
                backgroundColor: '#f7fffa', 
                border: '1px solid #e0e0e0', 
                borderRadius: '15px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' 
              }}>
                <h3 style={{ textAlign: 'center' }}>✨ Accept Your Partnership ✨</h3>
                <div style={{ textAlign: 'left' }}>
                  <p><strong>Inviter's Name:</strong> {owner.display_name}</p>
                  <p><strong>Inviter's Email:</strong> {owner.email}</p>
                  <p><strong>Partnership Description:</strong> {description}</p>
                  <p><strong>Partnership Type:</strong> {partnership_type}</p>
                  <p style={{ fontWeight: 'bold', color: '#6c757d' }}>
                    By accepting, you authorize {owner.display_name} to bill your account as per the terms of this partnership. You will receive notifications 48 hours in advance via phone and email before any transaction, and you have the right to cancel any transaction within that period and dispute after as well. Rest assured, everything is secure, and we monitor all transactions to ensure only valid charges are processed.
                  </p>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem', marginTop: '20px' }}>
                  <Button 
                    onClick={handleAccept} 
                    style={{ backgroundColor: '#1DB954', borderColor: '#1DB954' }}
                  >
                    Accept
                  </Button>
                  <Button 
                    onClick={handleDecline} 
                    style={{ backgroundColor: '#FF5A5F', borderColor: '#FF5A5F' }}
                  >
                    Decline
                  </Button>
                </div>
              </Card>
            </>
          );
        }
        return (
          <>
            <h2>{partnership_name}</h2>
            <p><strong>Owner:</strong> {owner.display_name}</p>
            <p><strong>Invited Partner:</strong> {partner.email}</p>
            <p><strong>Partnership Type:</strong> {partnership_type}</p>
            <p><strong>Description:</strong> {description}</p>
            <Card className="my-4 p-4" style={{ textAlign: 'center', border: '2px solid #ffde7a', borderRadius: '8px' }}>
              <h3>Waiting for the partner to accept the partnership.</h3>
              <p>
                Please notify your partner to accept the partnership request. They can follow the instructions sent to their email.
              </p>
              <h5>Copy Invite Link:</h5>
              <FormControl 
                readOnly 
                value={`${REACT_APP_URL}/invites/${partnership_invitation_id}`}
                onClick={handleCopyLink}
                style={{ cursor: 'pointer', textAlign: 'center', backgroundColor: '#f8f9fa' }}
              />
              {copied && (
                <Alert variant="success" className="mt-2">
                  Invite link copied to clipboard!
                </Alert>
              )}
            </Card>
          </>
        );
      case 'canceled':
        return (
          <>
            <h2>{partnership_name}</h2>
            <p><strong>Owner:</strong> {owner.display_name}</p>
            <p><strong>Partner:</strong> {partner.display_name}</p>
            <p><strong>Partnership Type:</strong> {partnership_type}</p>
            <p><strong>Description:</strong> {description}</p>
            <p>This bill partnership has been canceled or declined.</p>
          </>
        );
      default:
        return <div>Unknown status.</div>;
    }
  };

  const getDashboardTitle = () => {
    switch (status) {
      case 'active':
        return 'Active Bill Partnership';
      case 'pending':
        return 'Pending Bill Partnership';
      case 'canceled':
        return 'Canceled Bill Partnership';
      default:
        return 'Bill Partner Dashboard';
    }
  };

  return (
    <Card className="h-100">
      <Card.Body>
        <Card.Title>{getDashboardTitle()}</Card.Title>
        {renderContent()}
      </Card.Body>
    </Card>
  );
};

export default Dashboard;