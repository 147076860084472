import React, { useState } from 'react';
import { Form, Button, Modal, Spinner } from 'react-bootstrap';
import { PLATFORM_ENDPOINT, USER_TOKEN_KEY } from './constants';

const API_URL = process.env.REACT_APP_API_URL;

const ChargeForm = ({ partner, bp_id }) => {
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationId, setVerificationId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [verifying, setVerifying] = useState(false);

  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleCloseErrorModal = () => setShowErrorModal(false);
  const handleCloseVerificationModal = () => setShowVerificationModal(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = localStorage.getItem(USER_TOKEN_KEY);

    try {
      const response = await fetch(`${API_URL}/${PLATFORM_ENDPOINT}/send-charge-verification`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          amount: parseFloat(amount),
          description: description,
          bp_id: bp_id,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setVerificationId(data.verification_id);
        setShowVerificationModal(true);
      } else {
        const errorData = await response.json();
        if (errorData.error.includes("Stripe account is not yet verified")) {
          setErrorMessage("Your Stripe account is not yet verified. Please go to settings and KYC information to verify it or contact support.");
        } else if (errorData.error.includes("do not have permission to bill")) {
          setErrorMessage("You do not have permission to bill. Please contact support for more information.");
        } else {
          setErrorMessage(`Failed to send verification code. ${errorData.error || 'Please try again.'}`);
        }
        setShowErrorModal(true);
      }
    } catch (err) {
      console.error('Error sending verification code:', err);
      setErrorMessage('An unexpected error occurred. Please try again.');
      setShowErrorModal(true);
    } finally {
      setLoading(false);
    }
  };

  const resendVerificationCode = async () => {
    setLoading(true);
    const token = localStorage.getItem(USER_TOKEN_KEY);

    try {
      const response = await fetch(`${API_URL}/${PLATFORM_ENDPOINT}/send-charge-verification`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          amount: parseFloat(amount),
          description: description,
          bp_id: bp_id,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setVerificationId(data.verification_id);
        setErrorMessage('Your verification code has expired. A new code has been sent.');
        setShowErrorModal(true);
      } else {
        const errorData = await response.json();
        setErrorMessage(`Failed to resend verification code. ${errorData.error || 'Please try again.'}`);
        setShowErrorModal(true);
      }
    } catch (err) {
      console.error('Error resending verification code:', err);
      setErrorMessage('An unexpected error occurred. Please try again.');
      setShowErrorModal(true);
    } finally {
      setLoading(false);
    }
  };

  const handleVerificationSubmit = async () => {
    setVerifying(true);
    const token = localStorage.getItem(USER_TOKEN_KEY);

    try {
      // First, verify the code
      const verifyResponse = await fetch(`${API_URL}/${PLATFORM_ENDPOINT}/verify-charge-code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          verification_id: verificationId,
          verification_code: verificationCode,
        }),
      });

      if (verifyResponse.ok) {
        // If verification is successful, create the charge
        const chargeResponse = await fetch(`${API_URL}/${PLATFORM_ENDPOINT}/create-charge`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({
            verification_id: verificationId,
          }),
        });

        if (chargeResponse.ok) {
          setShowVerificationModal(false);
          setShowSuccessModal(true);

          // Clear the fields after successful charge creation
          setAmount('');
          setDescription('');
          setVerificationCode('');
        } else {
          const errorData = await chargeResponse.json();
          setErrorMessage(`Failed to create charge. ${errorData.error || 'Please try again.'}`);
          setShowErrorModal(true);
        }
      } else {
        const errorData = await verifyResponse.json();
        if (errorData.error === "Invalid or expired verification code") {
          // If the code is expired, resend a new verification code
          resendVerificationCode();
        } else {
          setErrorMessage(`Failed to verify code. ${errorData.error || 'Please try again.'}`);
          setShowErrorModal(true);
        }
      }
    } catch (err) {
      console.error('Error verifying code or creating charge:', err);
      setErrorMessage(err.toString())
      // setErrorMessage('An unexpected error occurred. Please try again.');
      setShowErrorModal(true);
    } finally {
      setVerifying(false);
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formChargeAmount">
          <Form.Label><strong>Charge Bill/Expense:</strong></Form.Label>
          <Form.Control
            type="number"
            placeholder="Amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
            disabled={loading || verifying}
          />
        </Form.Group>
        <Form.Group controlId="formChargeDetails" className="mt-2">
          <Form.Label><strong>Details:</strong></Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Details"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            disabled={loading || verifying}
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="mt-3" disabled={loading || verifying}>
          {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Submit Charge'}
        </Button>
      </Form>

      {/* Verification Modal */}
      <Modal show={showVerificationModal} onHide={handleCloseVerificationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Verify Charge</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please enter the 6-digit verification code sent to your email:</p>
          <Form.Control
            type="text"
            placeholder="Verification Code"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            required
            disabled={verifying}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseVerificationModal} disabled={verifying}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleVerificationSubmit} disabled={verifying}>
            {verifying ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Verify and Submit Charge'}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Success Modal */}
      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
        <Modal.Header closeButton>
          <Modal.Title>Charge Successful</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          The charge was initiated successfully.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleCloseSuccessModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Error Modal */}
      <Modal show={showErrorModal} onHide={handleCloseErrorModal}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMessage || "An error occurred. Please try again."}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseErrorModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChargeForm;



