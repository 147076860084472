import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Container } from 'react-bootstrap';
import { PLATFORM_ENDPOINT } from './constants';

const API_URL = process.env.REACT_APP_API_URL;

const EmailVerificationPage = () => {
  const { verification_uuid } = useParams();
  const [status, setStatus] = useState('loading');
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axios.post(`${API_URL}/${PLATFORM_ENDPOINT}/verify_email_verification`, {
          verification_uuid,
        });
        if (response.status === 200) {
          setStatus('success');
        } else {
          setStatus('error');
        }
      } catch (error) {
        console.error('Error verifying email:', error);
        setStatus('error');
      }
    };

    verifyEmail();
  }, [verification_uuid]);

  const handleGoHome = () => {
    navigate('/signin');
  };

  return (
    <Container className="text-center">
      {status === 'loading' && <p>Verifying your email...</p>}
      {status === 'success' && (
        <>
          <h2>Email Verified Successfully</h2>
          <p>Your email has been successfully verified. You can now use all the features of the platform.</p>
          <Button onClick={handleGoHome} variant="primary">
            Go to Sign In
          </Button>
        </>
      )}
      {status === 'error' && (
        <>
          <h2>Email Verification Failed</h2>
          <p>There was an issue verifying your email. Please try again later or contact support.</p>
          <Button onClick={handleGoHome} variant="secondary">
            Go to Sign In
          </Button>
        </>
      )}
    </Container>
  );
};

export default EmailVerificationPage;