import React, { useState, useEffect} from 'react';
import { Form, Button, Container, Row, Col, Spinner } from 'react-bootstrap';
import './KYCForm.css';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { USER_TOKEN_KEY } from './constants'; // Make sure to import your constant
import { useNavigate } from 'react-router-dom';
const KYCForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    displayName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    phoneNumber: '',
    ssnLast4: '', 
    dob: '' // Add date of birth field
  });

  // New state for phone error
  const [phoneError, setPhoneError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Modified handlePhoneChange function
  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phoneNumber: value });
    setPhoneError('');
  };

  // New useEffect hook for phone validation
  useEffect(() => {
    if (formData.phoneNumber) {
      const phoneNumber = parsePhoneNumber(formData.phoneNumber);
      if (phoneNumber) {
        if (!isValidPhoneNumber(formData.phoneNumber)) {
          setPhoneError('Invalid phone number');
        } else {
          setPhoneError('');
          // Update formData with E.164 formatted number
          setFormData(prevState => ({
            ...prevState,
            phoneNumber: phoneNumber.format('E.164')
          }));
        }
      } else {
        setPhoneError('Invalid phone number format');
      }
    } else {
      setPhoneError('');
    }
  }, [formData.phoneNumber]);

  // Modified validateForm function
  const validateForm = () => {
    if (phoneError) {
      setError(phoneError);
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setIsSubmitting(true); // Start showing the loader

    const token = localStorage.getItem(USER_TOKEN_KEY);

    if (!token) {
        setError('No token found. Please log in again.');
        setIsSubmitting(false); // Stop showing the loader
        return;
    }

    try {
        // Submit KYC data
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/platform/user_additional_info`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, // Pass the token in the Authorization header
            },
            body: JSON.stringify({
                first_name: formData.firstName,
                last_name: formData.lastName,
                display_name: formData.displayName,
                address_line1: formData.addressLine1,
                address_line2: formData.addressLine2,
                city: formData.city,
                state: formData.state,
                zip_code: formData.zipCode,
                phone_number: formData.phoneNumber,
                ssn_last4: formData.ssnLast4,
                dob: formData.dob // Include date of birth
            }),
        });

        const data = await response.json();

        if (!response.ok) {
            throw new Error(data.error || 'An error occurred while updating KYC information');
        }

        // Check for pending partnership and redirect based on flow_step
        if (data.flow_step === 2) {
          navigate('/bank-integration');
        } else {
          navigate('/');
        }

    } catch (err) {
        console.error('Error:', err);
        setError(err.message || 'An unexpected error occurred. Please try again.');
    }
  };


  return (
    <Container className="d-flex align-items-center justify-content-center min-vh-100">
      <div className="w-100 kyc-form-container">
        <h2 className="text-center mb-4 mt-5">Complete Your Profile</h2>
        <p className="text-center text-muted mb-5">This is a KYC (Know Your Customer) process. Please provide your details so we can enable billing for you</p>
        {error && <div className="alert alert-danger">{error}</div>}
        {success && <div className="alert alert-success">{success}</div>}
        <Form className="auth-form" onSubmit={handleSubmit}>
          <Row className="mb-4">
            <Col>
              <Form.Group controlId="firstName">
                <Form.Label className="bold-label">Legal First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="lastName">
                <Form.Label className="bold-label">Legal Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Text className="text-dark mb-4">
            Enter your name exactly as it is recorded with government agencies (i.e. IRS).
          </Form.Text>
          <br />
          <br />
          <Form.Group controlId="displayName" className="mb-4">
            <Form.Label className="bold-label">Display Name</Form.Label>
            <Form.Control
              type="text"
              name="displayName"
              value={formData.displayName}
              onChange={handleChange}
              placeholder="Name people will see e.g., Lunar Agency, Ray William"
              required
            />
          </Form.Group>
          <br />
          <h5 className="mb-3 bold-label">Home Address</h5>
          <Form.Group controlId="addressLine1" className="mb-3">
            <Form.Label>Address Line 1</Form.Label>
            <Form.Control
              type="text"
              name="addressLine1"
              value={formData.addressLine1}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="addressLine2" className="mb-3">
            <Form.Label>Address Line 2</Form.Label>
            <Form.Control
              type="text"
              name="addressLine2"
              value={formData.addressLine2}
              onChange={handleChange}
            />
          </Form.Group>
         
          <Row className="mb-4">
            <Col>
              <Form.Group controlId="city">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="state">
                <Form.Label>State</Form.Label>
                <Form.Control
                  as="select"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select State</option>
                  {["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "District of Columbia", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Puerto Rico", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"].map(state => (
                    <option key={state} value={state}>{state}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="zipCode">
                <Form.Label>ZIP Code</Form.Label>
                <Form.Control
                  type="text"
                  name="zipCode"
                  value={formData.zipCode}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Form.Group controlId="phoneNumber" className="mb-4">
            <Form.Label className="bold-label">Phone Number</Form.Label>
            <PhoneInput
              international
              countryCallingCodeEditable={false}
              defaultCountry="US"
              value={formData.phoneNumber}
              onChange={handlePhoneChange}
              error={phoneError}
            />
            {phoneError && <Form.Text className="text-danger">{phoneError}</Form.Text>}
          </Form.Group>
          <br />
          {/* Add Date of Birth field */}
          <Form.Group controlId="dob" className="mb-4">
              <Form.Label className="bold-label">Date of Birth</Form.Label>
              <Form.Control
                type="date"
                name="dob"
                value={formData.dob}
                onChange={handleChange}
                required
              />
          </Form.Group>
          <Form.Group controlId="ssnLast4" className="mb-4">
            <Form.Label className="bold-label">Last 4 digits of Social Security Number</Form.Label>
            <Form.Control
              type="password"
              name="ssnLast4"
              value={formData.ssnLast4}
              onChange={handleChange}
              required
              maxLength={4}
            />
            <br />
            <Form.Text className="text-muted">
              We are required to collect this information to satisfy regulatory obligations.
            </Form.Text>
          </Form.Group>
          
          <Button variant="primary" type="submit" className="w-100 mt-4 btn-blue" disabled={isSubmitting}>
            {isSubmitting ? (
              <>
                <Spinner animation="border" size="sm" /> Submitting...
              </>
            ) : (
              'Submit'
            )}
          </Button>
        </Form>
      </div>
    </Container>
  );
};

export default KYCForm;


