import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { PLATFORM_ENDPOINT, USER_TOKEN_KEY } from './constants';

const API_URL = process.env.REACT_APP_API_URL;

const BillPartnerDetails = ({ bp_id }) => {
  const [totals, setTotals] = useState({
    successful: 0,
    pending: 0,
    failed: 0,
  });
  const [partnerDetails, setPartnerDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem(USER_TOKEN_KEY);

      try {
        setLoading(true);
        
        // Fetch totals
        const totalsResponse = await fetch(`${API_URL}/${PLATFORM_ENDPOINT}/calculate-totals/${bp_id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (totalsResponse.ok) {
          const totalsData = await totalsResponse.json();
          setTotals(totalsData);
        } else {
          throw new Error('Failed to calculate totals.');
        }

        // Fetch partner details
        const detailsResponse = await fetch(`${API_URL}/${PLATFORM_ENDPOINT}/billpartnerships/${bp_id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (detailsResponse.ok) {
          const detailsData = await detailsResponse.json();
          setPartnerDetails(detailsData);
        } else {
          throw new Error('Failed to fetch partner details.');
        }

        setError(null);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [bp_id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const formatDateToPST = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      timeZone: 'America/Los_Angeles',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short'
    });
  };

  return (
    <Card className="p-3">
      <h3>Bill Partnership Details</h3>
      <div className="mt-3">
        <h4>Transaction Totals</h4>
        <p>Successful: ${totals.successful.toFixed(2)}</p>
        <p>Pending: ${totals.pending.toFixed(2)}</p>
        <p>Failed: ${totals.failed.toFixed(2)}</p>
      </div>
      {partnerDetails && (
        <div className="mt-4">
          <h4>Partnership Information</h4>
          <p><strong>Name:</strong> {partnerDetails.partnership_name}</p>
          <p><strong>Description:</strong> {partnerDetails.description}</p>
          <p><strong>Status:</strong> {partnerDetails.status}</p>
          <p><strong>Type:</strong> {partnerDetails.partnership_type}</p>
          <p><strong>Created:</strong> {formatDateToPST(partnerDetails.created_at)}</p>
          <h5>Owner</h5>
          <p>Name: {partnerDetails.owner.display_name}</p>
          <p>Email: {partnerDetails.owner.email}</p>
          <h5>Partner</h5>
          <p>Name: {partnerDetails.partner.display_name}</p>
          <p>Email: {partnerDetails.partner.email}</p>
        </div>
      )}
    </Card>
  );
};

export default BillPartnerDetails;