import React, { useEffect, useState } from 'react';
import { PLATFORM_ENDPOINT, USER_TOKEN_KEY } from './constants';
import { useParams, useNavigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

const CancelPage = () => {
  const { billPartnershipId, paymentIntentId } = useParams();
  const [message, setMessage] = useState('Cancelling payment...');
  const [showHomeButton, setShowHomeButton] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const cancelPayment = async () => {
      const token = localStorage.getItem(USER_TOKEN_KEY);

      try {
        const response = await fetch(`${API_URL}/${PLATFORM_ENDPOINT}/cancel/${billPartnershipId}/${paymentIntentId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setMessage(`Payment has been cancelled successfully! You may now close this page or return home.`);
        } else {
          const errorData = await response.json();
          setMessage(`This payment cannot be cancelled as it has already been cancelled or processed successfully. Please check your billing history for details.`);
        }
        setShowHomeButton(true);
      } catch (error) {
        console.error('Error cancelling payment. Please try again.', error);
        setMessage('An unexpected error occurred. Please try again or return home.');
        setShowHomeButton(true);
      }
    };

    cancelPayment();
  }, [billPartnershipId, paymentIntentId]);

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-12 text-center">
          <h1>{message}</h1>
          {showHomeButton && (
            <button 
              onClick={handleGoHome}
              className="btn btn-primary mt-3"
            >
              Go to Home Page
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CancelPage;